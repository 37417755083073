import { Flex, Image, Spinner } from "@chakra-ui/react";
import React from "react";
import Loader from "../../assets/gameporteLoader.gif";

const SpinLoader = ({ color }) => {
  return (
    <Flex mt="8rem" justifyContent={"center"}>
      {/* <Spinner size={"lg"} color={color || "#fff"} /> */}

      <Image mt="6rem" width={["42%", "18%"]} src={Loader} />
    </Flex>
  );
};

export default SpinLoader;
