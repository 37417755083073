import React, { useState, useEffect } from 'react';
import { Box, Flex, Stack, Spacer, Text, Link } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getWalletName } from '../../../../utils/helper';
import ProfileTag from './profileTag';
import { FaMobileAlt, FaPlaystation } from 'react-icons/fa';
import TeamImage from '../../../../assets/profile/team-image.png';
// import { Link } from 'react-router-dom';

function AccountDetails(props) {
  const { userProfile, wallet } = useSelector((state) => state.userData);
  const [consoleList, setConsoleList] = useState([]);

  const fullName = `${userProfile?.userProfile?.firstName} ${userProfile?.userProfile?.lastName}`;

  useEffect(() => {
    const consoleList = new Set();
    const isConsoleAvailable =
      userProfile?.userPreference?.prefConsole?.length > 0;

    if (isConsoleAvailable) {
      userProfile?.userPreference?.prefConsole?.map((item) => {
        consoleList.add({
          name: item?.console?.name,
          darkModeImageUrl: item?.console?.darkModeImageUrl,
          lightModeImageUrl: item?.console?.lightModeImageUrl,
        });
        return null;
      });
    } else {
      userProfile?.userPreference?.prefGames?.map((item) => {
        consoleList.add({
          name: item?.console?.name,
          darkModeImageUrl: item?.console?.darkModeImageUrl,
          lightModeImageUrl: item?.console?.lightModeImageUrl,
        });
        return null;
      });
    }
    setConsoleList(Array.from(consoleList));
  }, [userProfile]);

  return (
    <>
      <Flex flexDirection="column" gap="20px" padding={[3, 10]}>
        <Box
          display="flex"
          alignItems={{ base: 'center', md: 'flex-start' }}
          flexDirection={['column', null, null, 'row']}
          gap={[5]}
          width={'100%'}
        >
          <Text
            textAlign={['center', 'left']}
            fontWeight={['bold', 'normal']}
            fontSize="sm"
            color="#BBBBBB"
            minWidth="200px"
            textTransform="uppercase"
          >
            Name
          </Text>
          <ProfileTag bgColor="#1A1A1A">
            {userProfile?.userProfile?.firstName
              ? fullName
              : userProfile?.userProfile?.username}
          </ProfileTag>
        </Box>
        <Spacer borderBottom="1px solid #fff" />

        <Box
          display="flex"
          alignItems={{ base: 'center', md: 'flex-start' }}
          flexDirection={['column', null, null, 'row']}
          gap={[5]}
          width={'100%'}
        >
          <Text
            textAlign={['center', 'left']}
            fontWeight={['bold', 'normal']}
            fontSize="sm"
            color="#BBBBBB"
            minWidth="200px"
            textTransform="uppercase"
          >
            Date of Birth
          </Text>

          <ProfileTag bgColor="#1A1A1A">
            {userProfile?.userProfile?.dateOfBirth}
          </ProfileTag>
        </Box>
        <Spacer borderBottom="1px solid #fff" />

        <Box
          display="flex"
          alignItems={{ base: 'center', md: 'flex-start' }}
          flexDirection={['column', null, null, 'row']}
          gap={[5]}
          width={'100%'}
        >
          <Text
            textAlign={['center', 'left']}
            fontWeight={['bold', 'normal']}
            fontSize="sm"
            color="#BBBBBB"
            minWidth="200px"
            textTransform="uppercase"
          >
            Console
          </Text>
          <Stack
            spacing={[5, 10]}
            alignItems={{ base: 'center', md: 'flex-start' }}
            direction={['column', 'row']}
            width={'100%'}
          >
            {consoleList ? (
              consoleList.map((i) => (
                <ProfileTag bgColor="#1A1A1A" image={i.darkModeImageUrl}>
                  {i.name}
                </ProfileTag>
              ))
            ) : (
              <ProfileTag bgColor="#1A1A1A">N/A</ProfileTag>
            )}
          </Stack>
        </Box>
        <Spacer borderBottom="1px solid #fff" />

        <Box
          display="flex"
          alignItems={{ base: 'center', md: 'flex-start' }}
          flexDirection={['column', null, null, 'row']}
          gap={[5]}
          width={'100%'}
        >
          <Text
            textAlign={['center', 'left']}
            fontWeight={['bold', 'normal']}
            fontSize="sm"
            color="#BBBBBB"
            minWidth="200px"
          >
            PSN ID
          </Text>
          <Stack
            spacing={[5, 10]}
            alignItems={{ base: 'center', md: 'flex-start' }}
            direction={['column', 'row']}
            width={'100%'}
          >
            {userProfile?.userPreference?.prefGames.length > 0 ? (
              userProfile?.userPreference?.prefGames?.map((item) => (
                <ProfileTag bgColor="#1A1A1A">{`${item.console.name}: ${item.identity}`}</ProfileTag>
              ))
            ) : (
              <ProfileTag bgColor="#1A1A1A">N/A</ProfileTag>
            )}
          </Stack>
        </Box>
        <Spacer borderBottom="1px solid #fff" />

        <Box
          display="flex"
          alignItems={{ base: 'center', md: 'flex-start' }}
          flexDirection={['column', null, null, 'row']}
          gap={[5]}
          width={'100%'}
        >
          <Text
            textAlign={['center', 'left']}
            fontWeight={['bold', 'normal']}
            fontSize="sm"
            color="#BBBBBB"
            minWidth="200px"
            textTransform="uppercase"
          >
            Gamer Tags
          </Text>
          <Stack
            spacing={[5, 10]}
            alignItems={{ base: 'center', md: 'flex-start' }}
            direction={['column', 'row']}
            width={'100%'}
          >
            {userProfile?.userPreference?.prefGames.length > 0 ? (
              userProfile?.userPreference?.prefGames?.map((item) => (
                <ProfileTag bgColor="#1A1A1A">{`${item.game.name}: ${item.tag}`}</ProfileTag>
              ))
            ) : (
              <ProfileTag bgColor="#1A1A1A">N/A</ProfileTag>
            )}
          </Stack>
        </Box>
        <Spacer borderBottom="1px solid #fff" />

        <Box
          display="flex"
          alignItems={{ base: 'center', md: 'flex-start' }}
          flexDirection={['column', null, null, 'row']}
          gap={[5]}
          width={'100%'}
        >
          <Text
            textAlign={['center', 'left']}
            fontWeight={['bold', 'normal']}
            fontSize="sm"
            color="#BBBBBB"
            minWidth="200px"
            textTransform="uppercase"
          >
            Teams
          </Text>
          <Stack
            spacing={[5, 10]}
            alignItems={{ base: 'center', md: 'flex-start' }}
            direction={['column', 'row']}
            width={'100%'}
          >
            {userProfile?.teams?.content.length > 0 ? (
              userProfile?.teams?.content?.map((item) => (
                <Link
                  as={ReactRouterLink}
                  to={`/teams/${item.id}`}
                  style={{ display: 'block' }}
                  width={['100%', 'auto']}
                  _hover={{
                    textDecoration: 'none',
                  }}
                >
                  <ProfileTag bgColor="#1A1A1A" avatar={item.img}>
                    {`${item.name}: ${item.members.length} member${
                      item.members.length > 1 ? 's' : ''
                    }`}
                  </ProfileTag>
                </Link>
              ))
            ) : (
              <ProfileTag bgColor="#1A1A1A">N/A</ProfileTag>
            )}
          </Stack>
        </Box>
      </Flex>
    </>
  );
}

export default AccountDetails;
