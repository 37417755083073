import React, { useState } from "react";
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { CloseIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  AiFillCloseCircle,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { BsCheckCircleFill } from "react-icons/bs";
import { SearchIcon } from "../../assets";

const CustomInput = ({
  placeholder,
  type,
  resolving,
  resolvedData,
  ...props
}) => {
  // const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const transformedResolution = resolvedData || null;

  // const handleFocus = () => {
  //     setIsFocused(true);
  // };

  // const handleBlur = (event) => {
  //     if (!event.target.value) {
  //         setIsFocused(false);
  //     }
  // };

  return (
    <InputGroup position={"relative"}>
      {props.search && (
        <InputLeftElement
          zIndex={"7"}
          mt="1.5rem"
          ml=".5rem"
          children={<SearchIcon fontSize="20px" color="#9A9CA2" />}
        />
      )}
      <Input
        py={["1.3rem", "1.63rem"]}
        placeholder={placeholder}
        px={props.search ? "1.8rem" : "1rem"}
        borderRadius={props.search ? "35px" : "16px"}
        bg="#1A1A1A"
        fontSize={[".8rem", ".9rem"]}
        mt="1rem"
        zIndex={"6"}
        color={"#CFD0D3"}
        // onFocus={handleFocus}
        borderColor={props.showSuccess ? "#27AE60" : "#ffffff"}
        _focus={{
          borderColor: props.showSuccess ? "#27AE60" : "#ffffff",
          outline: "none",
          boxShadow: "none",
        }}
        _hover={{
          borderColor: props.showSuccess ? "#27AE60" : "#ffffff",
          outline: "none",
          boxShadow: "none",
        }}
        _placeholder={{ color: "#CFD0D3", fontSize: ".8rem" }}
        border={
          props.withBorder
            ? props.showSuccess
              ? "2px solid #27AE60"
              : "2px solid #1D5E82"
            : "none"
        }
        // borderColor={"#ffffff"}
        type={showPassword ? "text" : type}
        {...props}
      />
      {type === "password" && (
        <InputRightElement
          zIndex={"7"}
          mt="1.4rem"
          children={
            showPassword ? (
              <AiOutlineEye
                style={{
                  transform: "scale(1.4) translateX(-.4rem)",
                  cursor: "pointer",
                }}
                color="#CFD0D3"
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <AiOutlineEyeInvisible
                style={{
                  transform: "scale(1.4) translateX(-.4rem)",
                  cursor: "pointer",
                }}
                color="#CFD0D3"
                onClick={() => setShowPassword(!showPassword)}
              />
            )
          }
        />
      )}
      {resolving === true && (
        <InputRightElement
          zIndex={"7"}
          mt="1rem"
          ml=".-7rem"
          children={<Spinner size={["sm", "md"]} color="#CFD0D3" />}
        />
      )}
      {resolving !== true && transformedResolution !== null ? (
        <InputRightElement
          zIndex={"7"}
          mt={["1.15rem", "1.5rem"]}
          ml=".-7rem"
          children={
            <Text
              fontSize={[".7rem", ".8rem"]}
              fontWeight={"600"}
              transform={
                transformedResolution?.type === "error"
                  ? "translateX(-2.3rem)"
                  : "translateX(-1.5rem)"
              }
              color={
                transformedResolution?.type === "error"
                  ? "red.600"
                  : "green.600"
              }
              whiteSpace={"nowrap"}
            >
              {transformedResolution?.type === "error"
                ? transformedResolution?.errorText
                : transformedResolution?.successText}
            </Text>
          }
        />
      ) : null}
      {props.showSuccess && (
        <InputRightElement
          zIndex={"7"}
          mt="1.5rem"
          ml=".-7rem"
          children={<BsCheckCircleFill color="#27AE60" />}
        />
      )}
      {props.amount && (
        <InputRightElement
          zIndex={"7"}
          mt={["1rem", "1.5rem"]}
          mr="1.5rem"
          children={
            <Flex>
              <Text fontSize="13px" color="#CFD0D3">
                NGN
              </Text>{" "}
              <Text fontSize="13px" ml="8px" color="#0071FE">
                Max
              </Text>
            </Flex>
          }
        />
      )}
      {props.search && !props.showSuccess && (
        <InputRightElement
          zIndex={"7"}
          mt="1.5rem"
          mr=".5rem"
          children={<AiFillCloseCircle fontSize="20px" color="#02BAF1" />}
        />
      )}
    </InputGroup>
  );
};

export default CustomInput;
