import React from "react";
import AuthBg from "../assets/backgroundOne.png";
import { Box } from "@chakra-ui/react";

function UiWrapper(props) {
  return (
    <Box
      color="white"
      bg={"#000000"}
      borderRadius={["12px", "20px", "20px", "30px"]}
      border="1px solid #646464"
      p={["6px", "10px", "15px", "30px"]}
      backgroundSize="cover"
      h="fit-content"
      {...props}
    >
      {props.children}
    </Box>
  );
}

export default UiWrapper;
