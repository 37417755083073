import React from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

function ModalDiv(props) {
  return (
    <Modal
      isCentered
      motionPreset="slideInBottom"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalOverlay bg={"blackAlpha.300"} backdropFilter={"blur(10px)"} />
      <ModalContent
        bg="#000" /*bg="#003C4E"*/
        borderRadius={"16px"}
        w={["90%", "90%", "75%"]}
      >
        <ModalBody p="1.5rem">{props.children}</ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ModalDiv;
