import "./App.css";
import { Routes, Route, useNavigate, BrowserRouter } from "react-router-dom";
import { lazy, useCallback, useEffect, useState } from "react";
import { loaduser } from "./redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Image, Spinner, useToast } from "@chakra-ui/react";
import { userActionTypes } from "./redux/constants/userActionTypes";
import Loader from "./assets/gameporteLoader.gif";

import Profile from "./pages/dashboard/account/pages/Profile";
import { GetUserAccount } from "./services/accountApi.api";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import MessageWindow from "./components/chatModule/messageWindow/messageWindow";
import { useGlobalAppState } from "./contexts/AppContext";
// import Footer from "./pages/porteShopModule/components/footer/footer";
import Footer from "./components/footer/footer";

const Login = lazy(() => import("./pages/auth/login/login"));
const Signup = lazy(() => import("./pages/auth/signup/signup"));
const ForgotPassword = lazy(() =>
  import("./pages/auth/forgotPassword/forgotPassword")
);
const ResetPassword = lazy(() =>
  import("./pages/auth/forgotPassword/resetPassword")
);
const Verify = lazy(() => import("./pages/auth/verify/verify"));
const VerifySuccess = lazy(() => import("./pages/auth/verifySuccess"));
const Welcome = lazy(() => import("./pages/welcome/welcome"));
const Dashboard = lazy(() => import("./pages/dashboard/dashboard"));
const Explore = lazy(() => import("./pages/dashboard/explore/explore"));
const Home = lazy(() => import("./pages/dashboard/home/home"));
const Wallet = lazy(() => import("./pages/dashboard/wallet/wallet"));
const Tournaments = lazy(() =>
  import("./pages/dashboard/tournaments/tournaments")
);
const Chat = lazy(() => import("./pages/dashboard/chat/chat"));
const Account = lazy(() => import("./pages/dashboard/account/account"));
const ActivateWallet = lazy(() =>
  import("./pages/dashboard/wallet/page/ActivateWallet")
);
const History = lazy(() => import("./pages/dashboard/wallet/page/History"));
const TopUp = lazy(() => import("./pages/dashboard/wallet/page/TopUp"));
const TransactionDetails = lazy(() =>
  import("./pages/dashboard/wallet/page/TransactionDetails")
);
const Transfer = lazy(() => import("./pages/dashboard/wallet/page/Transfer"));
const InternalTransfer = lazy(() =>
  import("./pages/dashboard/wallet/page/InternalTransfer")
);
const ExternalTransfer = lazy(() =>
  import("./pages/dashboard/wallet/page/ExternalTransfer")
);
const BeneficiaryTransfer = lazy(() =>
  import("./pages/dashboard/wallet/page/BeneficiaryTransfer")
);
const AddBeneficiary = lazy(() =>
  import("./pages/dashboard/wallet/page/AddBeneficiary")
);
const TournamentDetails = lazy(() =>
  import("./pages/dashboard/tournaments/tournamentDetails/tournamentDetails")
);
const TeamsPage = lazy(() => import("./pages/dashboard/teams/teams"));
const TeamDetails = lazy(() =>
  import("./pages/dashboard/teams/teamDetails/teamDetails")
);
const CreateTeam = lazy(() =>
  import("./pages/dashboard/teams/createTeam/createTeam")
);
const InviteMembers = lazy(() =>
  import("./pages/dashboard/teams/createTeam/inviteMembers/inviteMembers")
);
const JoinTournament = lazy(() =>
  import("./pages/dashboard/tournaments/joinTournament/joinTournament")
);
const SelectMembers = lazy(() =>
  import(
    "./pages/dashboard/tournaments/joinTournament/selectMembers/selectMembers"
  )
);
const Settings = lazy(() => import("./pages/settings/settings"));
const EditProfile = lazy(() =>
  import("./pages/dashboard/account/pages/EditProfile")
);
const Inbox = lazy(() => import("./pages/dashboard/inbox/inbox"));
const PorteShop = lazy(() => import("./pages/porteShop/porteShop"));
const AccountTournaments = lazy(() =>
  import("./pages/dashboard/account/pages/Tournament")
);
const AccountMembership = lazy(() =>
  import("./pages/dashboard/account/pages/Membership")
);
const AccountTeam = lazy(() => import("./pages/dashboard/account/pages/Teams"));
const GamerProfile = lazy(() =>
  import("./pages/dashboard/explore/gamers/gamerProfile/gamerProfile")
);
const Follows = lazy(() =>
  import("./pages/dashboard/account/pages/follows/follows")
);
const Matches = lazy(() => import("./pages/dashboard/matches/Matches"));
const AllMatches = lazy(() => import("./pages/dashboard/matches/AllMatches"));
const MatchDetails = lazy(() =>
  import("./pages/dashboard/matches/MatchDetails")
);
const CreateMatch = lazy(() => import("./pages/dashboard/matches/CreateMatch"));
const MyMatches = lazy(() => import("./pages/dashboard/account/pages/Matches"));
const MyMatch = lazy(() => import("./pages/dashboard/matches/MyMatch"));
const PorteShopModule = lazy(() =>
  import("./pages/porteShopModule/porteShopModule")
);
const ShopHome = lazy(() =>
  import("./pages/porteShopModule/pages/home.jsx/home")
);
const Orders = lazy(() =>
  import("./pages/porteShopModule/pages/orders/orders")
);
const ShopLogin = lazy(() => import("./pages/auth/login/shopLogin"));
const NewProfile = lazy(() =>
  import("./pages/dashboard/account/pages/newProfile")
);
const NotFound = lazy(() => import("./components/NotFound"));

const firebaseConfig = {
  apiKey: "AIzaSyA61y5pTiZflrvWRtuEhdmu9feNFlwI0Z4",
  authDomain: "gameporte-fd7b4.firebaseapp.com",
  projectId: "gameporte-fd7b4",
  storageBucket: "gameporte-fd7b4.appspot.com",
  messagingSenderId: "119659385219",
  appId: "1:119659385219:web:fecb4d2d777ca2c1c766b6",
  measurementId: "G-8BP5VGKFFC",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toast = useToast();

  const loading = useSelector(({ userData }) => userData?.isLoading);
  const currentUser = useSelector(({ userData }) => userData?.currentUser);

  const [loadingx, setLoadingx] = useState(false);

  useEffect(() => {
    if (localStorage.GPV1TK) {
      dispatch(loaduser(navigate));
    } else {
      dispatch({ type: userActionTypes.LOADUSER_FAIL, payload: null });
    }
  }, [dispatch, navigate]);

  const getUserAccount = useCallback(async () => {
    try {
      setLoadingx(true);
      const { data } = await GetUserAccount();

      dispatch({ type: userActionTypes.USER_PROFILE_DATA2, payload: data });
    } catch (err) {
      setLoadingx(false);
      const errorMessage = "";
      toast({
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoadingx(false);
    }
  }, [toast, dispatch]);

  useEffect(() => {
    if (localStorage.GPV1TK) {
      getUserAccount();
    }
  }, [currentUser, getUserAccount]);

  return (
    <>
      {loading ? (
        <Flex justifyContent={"center"}>
          <Image mt="6rem" width={["42%", "18%"]} src={Loader} />
        </Flex>
      ) : (
        <div className="App">
          <Routes>
            <Route
              index
              path="/"
              element={
                currentUser ? <Dashboard component={<Home />} /> : <Login />
              }
            />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            {/* <Route path="/shop-login" element={<ShopLogin />} /> */}
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/forgot-password/reset" element={<ResetPassword />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/verify/success" element={<VerifySuccess />} />
            <Route
              path="/explore"
              element={<Dashboard component={<Explore />} />}
            />
            <Route
              path="/explore/gamer/:id"
              element={<Dashboard component={<Explore />} />}
            />
            <Route
              path="/wallet"
              element={<Dashboard component={<Wallet />} />}
            />
            <Route
              path="/activate-wallet"
              element={<Dashboard component={<ActivateWallet />} />}
            />
            <Route
              path="/history"
              element={<Dashboard component={<History />} />}
            />
            <Route
              path="history/:id"
              element={<Dashboard component={<TransactionDetails />} />}
            />
            <Route
              path="/top-up"
              element={<Dashboard component={<TopUp />} />}
            />
            <Route
              path="/transfer"
              element={<Dashboard component={<Transfer />} />}
            />
            <Route
              path="/transfer/internal"
              element={<Dashboard component={<InternalTransfer />} />}
            />
            <Route
              path="/transfer/external"
              element={<Dashboard component={<ExternalTransfer />} />}
            />
            <Route
              path="/transfer/beneficiary"
              element={<Dashboard component={<BeneficiaryTransfer />} />}
            />
            <Route
              path="/transfer/add-beneficiary"
              element={<Dashboard component={<AddBeneficiary />} />}
            />
            <Route
              path="/matches"
              element={<Dashboard component={<Matches />} />}
            />
            <Route
              path="/matches/:id"
              element={<Dashboard component={<AllMatches />} />}
            />
            <Route
              path="/match/:id"
              element={<Dashboard component={<MatchDetails />} />}
            />
            <Route
              path="/my-match/:id"
              element={<Dashboard component={<MyMatch firebaseApp={app} />} />}
            />
            <Route
              path="/create-match/:username"
              element={<Dashboard component={<CreateMatch />} />}
            />
            <Route
              path="/tournaments"
              element={<Dashboard component={<Tournaments />} />}
            />
            <Route
              path="/tournaments/:id"
              element={<Dashboard component={<TournamentDetails />} />}
            />
            <Route
              path="/tournaments/:id/join"
              element={<Dashboard component={<JoinTournament />} />}
            />
            <Route
              path="/tournaments/:id/join/members"
              element={<Dashboard component={<SelectMembers />} />}
            />
            {/* <Route
              path="/chat"
              element={<Dashboard component={<Chat firebaseApp={app} />} />}
            />
            <Route
              path="/chat/:uid"
              element={
                <Dashboard component={<MessageWindow firebaseApp={app} />} />
              }
            /> */}
            <Route
              path="/account"
              element={<Dashboard component={<Account />} />}
            />
            <Route
              path="/profile"
              element={<Dashboard component={<NewProfile />} />}
            />
            {/* <Route
              path="/newProfile"
              element={
                <Dashboard
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  component={<NewProfile />}
                />
              }
            /> */}
            <Route
              path="/profile/follows"
              element={<Dashboard component={<Follows />} />}
            />
            <Route
              path="/edit-profile"
              element={<Dashboard component={<EditProfile />} />}
            />
            <Route
              path="/settings"
              element={<Dashboard component={<Settings />} />}
            />
            <Route
              path="/inbox"
              element={<Dashboard component={<Inbox />} />}
            />
            <Route
              path="/teams"
              element={<Dashboard component={<TeamsPage />} />}
            />
            <Route
              path="/teams/:id"
              element={<Dashboard component={<TeamDetails />} />}
            />
            <Route
              path="/gamer/:id"
              element={
                <Dashboard component={<GamerProfile firebaseApp={app} />} />
              }
            />
            <Route
              path="/gamer/:id/follows"
              element={<Dashboard component={<Follows />} />}
            />
            <Route
              path="/teams/:id/invite"
              element={<Dashboard component={<InviteMembers />} />}
            />
            <Route
              path="/teams/create"
              element={<Dashboard component={<CreateTeam />} />}
            />
            {/* <Route
              path="/shop"
              element={<Dashboard component={<PorteShop />} />}
            />
            <Route
              path="/porteshop"
              element={<PorteShopModule component={<ShopHome />} />}
            />
            <Route
              path="/porteshop/orders"
              element={<PorteShopModule component={<Orders />} />}
            /> */}
            <Route
              path="/account/tournaments"
              element={<Dashboard component={<AccountTournaments />} />}
            />
            <Route
              path="/account/matches"
              element={<Dashboard component={<MyMatches />} />}
            />
            <Route
              path="/account/membership"
              element={<Dashboard component={<AccountMembership />} />}
            />
            <Route
              path="/account/teams"
              element={<Dashboard component={<AccountTeam />} />}
            />
            <Route path="*" element={<Dashboard component={<NotFound />} />} />
          </Routes>
          <Footer  />
        </div>
      )}
    </>
  );
}

export default App;
