import { Avatar, Box, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { ReactComponent as CardImageLeft } from '../../../../assets/profile/left-card-border.svg';
import { ReactComponent as CardImageRight } from '../../../../assets/profile/right-card-border.svg';

const ProfileTag = ({
  avatar,
  bgColor,
  children,
  color,
  icon,
  iconColor,
  image,
  style,
}) => {
  const styles = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '10px 25px',
    position: 'relative',
    outline: 'none',
    fontFamily: 'Orbitron, sans-serif',
    fontStyle: 'normal',
    fontSize: '.7rem',
    color: '#fff',
    clipPath: 'polygon(5% 0, 100% 0, 100% 80%, 95% 100%, 0 100%, 0 20%)',
  };

  return (
    <Box as={'div'} position="relative" width={['100%', 'auto']}>
      <CardImageLeft
        style={{
          position: 'absolute',
          maxWidth: '100%',
          top: '-8px',
          left: '-5px',
          zIndex: 5,
        }}
      />
      <Box sx={styles} style={style} color={color} backgroundColor={bgColor}>
        {avatar && (
          <Avatar
            name={children}
            bg="transparent"
            src={avatar}
            borderRadius={2}
            size="sm"
          />
        )}

        {icon && <Icon as={icon} color={iconColor} boxSize={6} />}
        <Text>{`${children} ${image ? ':' : ''}`}</Text>

        {image && <img src={image} alt={children} width="30px" />}
      </Box>
      <CardImageRight
        style={{
          position: 'absolute',
          maxWidth: '100%',
          bottom: '-8px',
          right: '-5px',
          zIndex: 5,
        }}
      />
    </Box>
  );
};

export default ProfileTag;
