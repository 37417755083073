import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { AppProvider } from "./contexts/AppContext";
import SpinLoader from "./components/spinLoader/spinLoader";

const breakpoints = {
  base: "0px",
  sm: "480px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

const theme = extendTheme({ breakpoints });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Suspense fallback={<SpinLoader />}>
          <Provider store={store}>
            <GoogleOAuthProvider clientId="380862278482-50p2mlq8t17pikhsino7i53a2rsiglsq.apps.googleusercontent.com">
              <AppProvider>
                <App />
                <Analytics />
                <SpeedInsights />
              </AppProvider>
            </GoogleOAuthProvider>
          </Provider>
        </Suspense>
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
