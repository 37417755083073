export const userActionTypes = {
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAIL: 'SIGNUP_FAIL',
  SIGNIN_SUCCESS: 'SIGNIN_SUCCESS',
  SIGNIN_SUCCESS2: 'SIGNIN_SUCCESS2',
  SIGNIN_FAIL: 'SIGNIN_FAIL',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAIL: 'LOGOUT_FAIL',
  LOADUSER_SUCCESS: 'LOADUSER_SUCCESS',
  LOADUSER_SUCCESS2: 'LOADUSER_SUCCESS2',
  LOADUSER_FAIL: 'LOADUSER_FAIL',
  BIKEREQLOADER: 'BIKEREQLOADER',
  WALLET_DETAILS: 'WALLET_DETAILS',
  TRANSACTIONS: 'TRANSACTIONS',
  ADD_TRANSACTIONS_DATA: 'ADD_TRANSACTIONS_DATA',
  UPDATE_NOTIFICATIONS_COUNT: 'UPDATE_NOTIFICATIONS_COUNT',
  USER_PROFILE_DATA: 'USER_PROFILE_DATA',
  USER_PROFILE_DATA2: 'USER_PROFILE_DATA2',
};
