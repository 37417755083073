import { Button, Spinner, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { HiOutlineArrowNarrowRight, HiOutlinePlus } from 'react-icons/hi';

const OutlineButton = (
  props,
  { text, color, bg, func, loading, isDisabled, icon }
) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <Button
      bg="transparent"
      fontSize=".9rem"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      fontWeight={'600'}
      fontFamily={'Open Sans'}
      borderRadius={['8px', '12px']}
      h={props.height ? props.height : 'auto'}
      padding={props.height ? '15px' : '1rem 1.5rem'}
      border="2px solid #0071FE"
      width={'100%'}
      _hover={{ backgroundColor: 'transpraent' }}
      transition={'250ms ease'}
      _disabled={{
        backgroundColor: 'transpraent',
        cursor: 'not-allowed',
        color: '#0071FE',
      }}
      {...props}
    >
      {props.loading === true ? (
        <Spinner color={isHover ? '#FFF' : '#0A0A0B'} />
      ) : (
        <Text
          ml={icon?.length > 0 ? '-.5rem' : ''}
          color={isHover ? '#FFF' : props?.color || '#0071FE'}
        >
          {props?.text}
          {props?.icon === 'next' && (
            <HiOutlineArrowNarrowRight
              style={{
                transform: 'scale(1.3) translateY(2px)',
                display: 'inline',
                marginLeft: '.5rem',
              }}
            />
          )}
          {props?.icon === 'add' && (
            <HiOutlinePlus
              style={{
                transform: 'scale(1.3) translateY(2px)',
                display: 'inline',
                marginLeft: '.5rem',
              }}
            />
          )}
        </Text>
      )}
    </Button>
  );
};

export default OutlineButton;
