import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import FullLogo from "../../assets/full-logo.svg";
import Discord from "./icons/discord.svg";
import Facebook from "./icons/facebook.svg";
import Youtube from "./icons/youtube.svg";
import Twitter from "./icons/twitter.svg";
import Instagram from "./icons/instagram.svg";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const footerLinks = [
  { title: "Home", link: "/" },
  { title: "Porte Shop", link: "https://shop.gameporte.com/" },
  { title: "About us", link: "https://www.gameporte.com/about" },
  { title: "Contact us", link: "mailto:hello@gameporte.com" },
  { title: "FAQ", link: "https://www.gameporte.com/faq" },
  { title: "Privacy", link: "https://www.gameporte.com/privacy-policy" },
  { title: "Terms & Conditions", link: "https://www.gameporte.com/terms" },
  // { title: "Sponsorship", link: "" },
];

const socials = [
  { icon: Discord, link: "https://discord.gg/9FmPYHB7" },
  { icon: Twitter, link: "https://twitter.com/gameporte" },
  { icon: Instagram, link: "https://instagram.com/gameporte?igshid=YmMyMTA2M2Y=" },
  { icon: Facebook, link: "https://www.facebook.com/gameporte" },
  { icon: Youtube, link: "https://youtube.com/channel/UCoD1MdZ_SZVGakXIJ3WlSEA" },
];

const Footer = ({ collapsed }) => {
  const currentUser = useSelector(({ userData }) => userData?.currentUser);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleLinkClick = (link) => {
    if (link.startsWith("http") || link.startsWith("mailto")) {
      window.open(link, "_blank");
    } else {
      navigate(link);
    }
  };

  return (
    <Box ml={collapsed ? ["0", "0", "80px", "80px"] : currentUser && !pathname?.includes('welcome') ? ["0", "0", "240px", "240px"] : "0"} transform={"250ms ease"}>
      <Box bg="black" padding={["3rem 1rem", "3rem 3rem"]}>
        <Flex justifyContent={"center"}>
          <Flex gap=".7rem" flexWrap={"wrap"} justifyContent={"space-around"}>
            {footerLinks.map(({ title, link }) => (
              <Text
                whiteSpace={"nowrap"}
                cursor={"pointer"}
                key={title}
                borderRadius={"40px"}
                fontSize={".9rem"}
                color={"#FFF"}
                fontWeight={"500"}
                padding={"10px 24px"}
                border="1px solid white"
                onClick={() => handleLinkClick(link)}
              >
                {title}
              </Text>
            ))}
          </Flex>
        </Flex>
        <Box my="2.2rem" width={"80%"} mx="auto" borderTop={"2px solid white"} />
        <Image src={FullLogo} width={["50%", "13%"]} mx="auto" />
        <Text color={"#FFF"} my={"20px"} fontSize={"20px"} textAlign={"center"} fontWeight={"500"}>
          &copy; Gameporte Interactive Inc.
        </Text>
        <Flex mt="2rem" gap={"1.5rem"} width={"fit-content"} mx="auto">
          {socials.map(({ icon, link }, index) => (
            <Image
              cursor={"pointer"}
              transition={"250ms ease"}
              _hover={{ transform: "scale(.9)" }}
              key={index}
              src={icon}
              onClick={() => window.open(link, "_blank")}
            />
          ))}
        </Flex>
      </Box>
      <Box bg="#000F14" borderTop={"2px solid white"} py="1.7rem">
        <Text
          color={"#EAEAEB"}
          fontSize={".85rem"}
          textAlign={"center"}
          maxW={"85%"}
          mx="auto"
        >
          Gameporte is not endorsed by, directly affiliated with, maintained or sponsored by Apple Inc, Electronic Arts, Activision Blizzard, Take-Two Interactive, Microsoft, Xbox, Sony, PlayStation or Epic Games. All content, games titles, trade names and/or trade dress, trademarks, artwork and associated imagery are trademarks and/or copyright material of their respective owners.
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
