import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomInput from "../../../../components/customInput/customInput";
import OutlineButton from "../../../../components/primaryButton/OutlineButton";
import PrimaryButton from "../../../../components/primaryButton/primarybutton";
import { UpdateSocials } from "../../../../services/accountApi.api";
import { getErrorMessge } from "../../../../utils/helper";
import ModalDiv from "../../../../components/ModalDiv";
import { BsDiscord, BsTwitter } from "react-icons/bs";
import { HiChevronRight } from "react-icons/hi";
import { AiFillInstagram } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import ProfileTag from "./profileTag";

function Socials(props) {
  const [editSocials, setEditSocials] = useState(false);
  const [curr, setCurr] = useState("");
  const { userProfile } = useSelector((state) => state.userData);
  const [links, setLinks] = useState({
    twitter: null,
    discord: null,
    telegram: null,
    instagram: null,
  });
  const [editModal, setEditModal] = useState(false);
  const [isEditing, setIsEditing] = useState({
    twitter: false,
    discord: false,
    telegram: false,
    instagram: false,
  });
  const [loading, setLoading] = useState(false);
  const handleChange = (name, value) => {
    setLinks({
      ...links,
      [name]: value,
    });
  };

  const toast = useToast();

  useEffect(() => {
    setLinks({
      ...links,
      discord: userProfile?.socials?.discord || null,
      telegram: userProfile?.socials?.telegram || null,
      twitter: userProfile?.socials?.twitter || null,
      instagram: userProfile?.socials?.instagram || null,
    });
  }, [userProfile]);

  const updateSocials = async (action) => {
    let payload;
    if (action !== "add") {
      payload = {
        ...links,
        [action]: "",
      };
    } else {
      payload = { ...links };
    }
    try {
      setLoading(true);
      const res = await UpdateSocials(payload);
      setLinks({
        twitter: res.data.twitter,
        telegram: res.data.telegram,
        discord: res.data.discord,
        instagram: res.data.instagram,
      });
      toast({
        description: "Social link Updated",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      setIsEditing({
        twitter: false,
        discord: false,
        telegram: false,
        instagram: false,
      });
      setEditSocials(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 500 || error?.response?.status === 501) {
        toast({
          title: "",
          description:
            "An error occurred while processing this request, please contact administrator",
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      } else {
        const errMsg = getErrorMessge(error);
        toast({
          description: errMsg,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (val) => {
    setCurr(val);
    setEditModal(true);
  };

  return (
    <Box>
      {!editSocials ? (
        <>
          <Flex
            onClick={() =>
              links.discord !== null
                ? window.open(links.discord, "_blank")
                : handleEdit("discord")
            }
            cursor={"pointer"}
            position={"relative"}
            alignItems={"center"}
            gap=".4rem"
            bg="gray.900"
            p={[".8rem .5rem", "1rem"]}
            borderRadius={["0", "12px 12px 0 0"]}
            borderBottom={"1px solid #FFF"}
          >
            <ProfileTag
              fontSize={[".8rem", ".9rem"]}
              fontWeight={"600"}
              bgColor="#1A1A1A"
              icon={BsDiscord}
            >
              Discord
            </ProfileTag>
            <HiChevronRight
              style={{ position: "absolute", right: "2%" }}
              color="#FFFFFF"
            />
          </Flex>
          <Flex
            onClick={() =>
              links.twitter !== null
                ? window.open(links.twitter, "_blank")
                : handleEdit("twitter")
            }
            cursor={"pointer"}
            position={"relative"}
            alignItems={"center"}
            gap=".4rem"
            bg="gray.900"
            p={[".8rem .5rem", "1rem"]}
            borderBottom={"1px solid #FFF"}
          >
            <ProfileTag
              fontSize={[".8rem", ".9rem"]}
              fontWeight={"600"}
              bgColor="#1A1A1A"
              icon={BsTwitter}
              iconColor="#1DA1F2"
            >
              Twitter
            </ProfileTag>
            <HiChevronRight
              style={{ position: "absolute", right: "2%" }}
              color="#FFFFFF"
            />
          </Flex>
          <Flex
            onClick={() =>
              links.instagram !== null
                ? window.open(links.instagram, "_blank")
                : handleEdit("instagram")
            }
            cursor={"pointer"}
            position={"relative"}
            alignItems={"center"}
            gap=".4rem"
            bg="gray.900"
            p={[".8rem .5rem", "1rem"]}
            borderBottom={"1px solid #FFF"}
          >
            <ProfileTag
              fontSize={[".8rem", ".9rem"]}
              fontWeight={"600"}
              bgColor="#1A1A1A"
              icon={AiFillInstagram}
              iconColor="#833AB4"
            >
              Instagram
            </ProfileTag>
            <HiChevronRight
              style={{ position: "absolute", right: "2%" }}
              color="#FFFFFF"
            />
          </Flex>
          <Flex
            onClick={() =>
              links.telegram !== null
                ? window.open(links.telegram, "_blank")
                : handleEdit("tiktok")
            }
            cursor={"pointer"}
            position={"relative"}
            alignItems={"center"}
            gap=".4rem"
            bg="gray.900"
            p={[".8rem .5rem", "1rem"]}
            borderRadius={"0 0 12px 12px"}
          >
            <ProfileTag
              fontSize={[".8rem", ".9rem"]}
              fontWeight={"600"}
              bgColor="#1A1A1A"
              icon={FaTiktok}
            >
              TikTok
            </ProfileTag>
            <HiChevronRight
              style={{ position: "absolute", right: "2%" }}
              color="#FFFFFF"
            />
          </Flex>
        </>
      ) : (
        <Box bg="#002F3D" borderRadius="20px" w="100%">
          <Flex
            w="100%"
            px={["10px", "10px", "20px"]}
            py=".3rem"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex
              transform={"scale(.8)"}
              height={"32px"}
              width={"46px"}
              bg="#5865F2"
              justifyContent={"center"}
              alignItems={"center"}
              borderRadius={"6px"}
            >
              <BsDiscord
                color="#FFF"
                style={{ transform: "scale(1.8)", width: "100%" }}
              />
            </Flex>
            <CustomInput
              value={links.discord}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
                setIsEditing({ ...isEditing, discord: true });
              }}
              name="discord"
              withBorder={false}
              placeholder={"Discord Link"}
              mt="0"
            />
            <Box w="80px">
              {links.discord && !isEditing.discord ? (
                <OutlineButton
                  onClick={() => updateSocials("discord")}
                  text="Remove"
                  height="7px"
                />
              ) : (
                <PrimaryButton
                  onClick={() => updateSocials("add")}
                  text="Add"
                  height="7px"
                />
              )}
            </Box>
          </Flex>
          <Flex
            w="100%"
            px={["10px", "10px", "20px"]}
            justifyContent="space-between"
            borderTop="1px solid #003C4E"
            alignItems="center"
            py=".3rem"
          >
            <Flex
              transform={"scale(.8)"}
              height={"32px"}
              width={"46px"}
              justifyContent={"center"}
              alignItems={"center"}
              borderRadius={"6px"}
            >
              <BsTwitter
                color="#1DA1F2"
                transform="scale(7)"
                style={{ transform: "scale(2.2)", width: "100%" }}
              />
            </Flex>
            <CustomInput
              value={links.twitter}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
                setIsEditing({ ...isEditing, twitter: true });
              }}
              name="twitter"
              withBorder={false}
              placeholder={"Twitter Link"}
              mt="0"
            />
            <Box w="80px">
              {links.twitter && !isEditing.twitter ? (
                <OutlineButton
                  onClick={() => updateSocials("twitter")}
                  text="Remove"
                  height="7px"
                />
              ) : (
                <PrimaryButton
                  onClick={() => updateSocials("add")}
                  text="Add"
                  height="7px"
                />
              )}
            </Box>
          </Flex>
          <Flex
            w="100%"
            px={["10px", "10px", "20px"]}
            justifyContent="space-between"
            borderTop="1px solid #003C4E"
            alignItems="center"
            py=".3rem"
          >
            <Flex
              transform={"scale(.8)"}
              height={"32px"}
              width={"46px"}
              justifyContent={"center"}
              alignItems={"center"}
              borderRadius={"6px"}
            >
              <AiFillInstagram
                color="#833AB4"
                style={{ transform: "scale(2.6)", width: "100%" }}
              />
            </Flex>
            <CustomInput
              value={links.instagram}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
                setIsEditing({ ...isEditing, instagram: true });
              }}
              name="instagram"
              withBorder={false}
              placeholder={"Instagram Link"}
              mt="0"
            />
            <Box w="80px">
              {links.instagram && !isEditing.instagram ? (
                <OutlineButton
                  onClick={() => updateSocials("instagram")}
                  text="Remove"
                  height="7px"
                />
              ) : (
                <PrimaryButton
                  onClick={() => updateSocials("add")}
                  text="Add"
                  height="7px"
                />
              )}
            </Box>
          </Flex>
          <Flex
            w="100%"
            px={["10px", "10px", "20px"]}
            py=".3rem"
            justifyContent="space-between"
            borderTop="1px solid #003C4E"
            alignItems="center"
          >
            <Flex
              transform={"scale(.8)"}
              height={"32px"}
              width={"46px"}
              justifyContent={"center"}
              alignItems={"center"}
              borderRadius={"6px"}
            >
              <FaTiktok
                color="#FFF"
                style={{ transform: "scale(2)", width: "100%" }}
              />
            </Flex>
            <CustomInput
              value={links.telegram}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
                setIsEditing({ ...isEditing, telegram: true });
              }}
              name="telegram"
              withBorder={false}
              placeholder={"TikTok Link"}
              mt="0"
            />
            <Box w="80px">
              {links.telegram && !isEditing.telegram ? (
                <OutlineButton
                  onClick={() => updateSocials("telegram")}
                  text="Remove"
                  height="7px"
                />
              ) : (
                <PrimaryButton
                  onClick={() => updateSocials("add")}
                  text="Add"
                  height="7px"
                />
              )}
            </Box>
          </Flex>

          <Flex justifyContent={"center"} my="20px" pb="20px">
            <Box w="98%">
              <PrimaryButton
                height="45px"
                text="Add all"
                onClick={() => updateSocials("add")}
              />
            </Box>
          </Flex>
        </Box>
      )}

      <ModalDiv isOpen={editModal} onClose={() => setEditModal(false)}>
        <Box w="100%" color="#fff">
          <Text fontSize={["18px", "22px"]}>Oops</Text>
          <Text fontSize={["16px", "16px", "22px"]} mt="20px">
            No {curr} account available
          </Text>
          <Flex mt="30px" justifyContent={"flex-end"} w="100%">
            <Text
              cursor={"pointer"}
              onClick={() => setEditModal(false)}
              fontSize={["16px", "16px", "18px"]}
              padding="5px 10px"
            >
              Cancel
            </Text>
            <Text
              bg="#0071FE"
              cursor={"pointer"}
              onClick={() => {
                setEditModal(false);
                setEditSocials(true);
              }}
              ml="30px"
              fontSize={["16px", "16px", "18px"]}
              borderRadius={["5px", "8px"]}
              padding="5px 10px"
              // width={"100%"}
              _hover={{ backgroundColor: "#101828" }}
              transition={"250ms ease"}
            >
              Update Now
            </Text>
          </Flex>
        </Box>
      </ModalDiv>
    </Box>
  );
}

export default Socials;
