import { userActionTypes } from '../constants/userActionTypes';
import axiosInstance from '../../services/api';
import { GetWallet } from '../../services/wallet.api';

export const loaduser = (navigate) => async (dispatch) => {
  try {
    if (localStorage.GPV1TK) {
      const { data } = await axiosInstance.get('/dashboard');

      dispatch({ type: userActionTypes.LOADUSER_SUCCESS, payload: data?.data });
      if (
        data?.data?.gamingPreferences?.length < 1 &&
        localStorage.userSkipped === 'false'
      ) {
        navigate('/welcome');
      }
      // if (data?.data?.hasConsolePreference === false || data?.data?.hasGamePreference === false) {
      //     navigate('/welcome');
      // }
      // else {
      //    navigate('/')
      // }
    } else {
      localStorage.removeItem('GPV1TK');
      window.location.href = '/';
    }
  } catch (error) {
    dispatch({ type: userActionTypes.LOADUSER_FAIL, payload: null });
    localStorage.removeItem('GPV1TK');
    window.location.href = '/';
    if (error.response) {
      localStorage.removeItem('GPV1TK');
      window.location.href = '/';
    }
  }
};

export const getWalletDetails = (navigate) => async (dispatch) => {
  try {
    const { data } = await GetWallet();
    dispatch({ type: userActionTypes.WALLET_DETAILS, payload: data });
  } catch (error) {
    console.error(error);
  }
};
