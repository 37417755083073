import { createContext, useContext, useState } from "react";

const AppContext = createContext();

function AppProvider({ children }) {
  const [collapsed, setCollapsed] = useState(false);

  function collapse() {
    setCollapsed(!collapsed);
  }

  return (
    <AppContext.Provider
      value={{
        collapsed,
        setCollapsed: collapse,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

function useGlobalAppState() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("Context not available in this scope");
  }

  return context;
}

export { AppProvider, useGlobalAppState };
