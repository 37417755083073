import { Button, Spinner, Text } from "@chakra-ui/react";
import { useState } from "react";
import { FaTrophy } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import { HiOutlineArrowNarrowRight, HiOutlinePlus } from "react-icons/hi";

const PrimaryButton = (
  props,
  { text, color, bg, func, loading, isDisabled, icon }
) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <Button
      backgroundColor={bg || "#0071FE"}
      fontSize={[".75rem", ".9rem"]}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      fontWeight={"600"}
      fontFamily={"Open Sans"}
      h={props.height ? props.height : ["38px", "auto"]}
      borderRadius={["8px", "12px"]}
      padding={props.height ? "15px" : "1rem 1.5rem"}
      width={"100%"}
      _hover={{ backgroundColor: "#101828" }}
      // _hover={{ backgroundColor: "#0071FE" }}
      transition={"250ms ease"}
      _disabled={{
        backgroundColor: "#646464",
        cursor: "not-allowed",
        color: "#ffffff",
      }}
      {...props}
    >
      {props.loading === true ? (
        <Spinner color={isHover ? "#FFF" : "#0A0A0B"} />
      ) : (
        <Text
          ml={icon?.length > 0 ? "-.5rem" : ""}
          color={isHover ? "#FFF" : props?.color || "#ffffff"}
        >
          {props?.icon === "tournament" && (
            <FaTrophy
              style={{
                transform: "scale(1.3) translateY(2px)",
                display: "inline",
                marginRight: "1.2rem",
              }}
            />
          )}
          {props?.icon === "chat" && (
            <FiSend
              style={{
                transform: "scale(1.3) translateY(2px)",
                display: "inline",
                marginRight: ".5rem",
              }}
            />
          )}
          {props?.text}
          {props?.icon === "next" && (
            <HiOutlineArrowNarrowRight
              style={{
                transform: "scale(1.3) translateY(2px)",
                display: "inline",
                marginLeft: ".5rem",
              }}
            />
          )}
          {props?.icon === "add" && (
            <HiOutlinePlus
              style={{
                transform: "scale(1.3) translateY(2px)",
                display: "inline",
                marginLeft: ".5rem",
              }}
            />
          )}
        </Text>
      )}
    </Button>
  );
};

export default PrimaryButton;
