import User from "./../components/sidebar/icons/user.svg";

export default function LightenDarkenColor(col, amt) {
  var num = parseInt(col, 16);
  var r = (num >> 16) + amt;
  var b = ((num >> 8) & 0x00ff) + amt;
  var g = (num & 0x0000ff) + amt;
  var newColor = g | (b << 8) | (r << 16);
  return newColor.toString(16);
}

export function truncateText(text, length) {
  let newText = text;
  if (text?.length > length) {
    newText = text?.slice(0, length);
    return newText + "...";
  }
  return newText;
}

export const readableDate = (date) => {
  const options = {
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZone: "Africa/Lagos",
  };

  const formattedDate = new Date(date).toLocaleDateString("en-US", options);
  const [month, day, year] = formattedDate.split(" ");
  return ` ${month} ${day} ${year}`;
};

export function convertToProperObject(data) {
  const formattedData = {};

  for (const key in data) {
    const unescapedKey = JSON.parse(key); // Remove the extra quotes using JSON.parse
    formattedData[unescapedKey] = data[key];
  }

  return formattedData;
}

export function removeQuotes(inputString) {
  return inputString.replace(/"/g, "");
}

export const getErrorMessge = (error) => {
  const message = error?.response?.data?.errors[0];

  return message;
};

export const getIntials = (str) => {
  const stringOne = str.trim().split(" ")[0];
  const stringTwo = str.trim().split(" ")[1];

  if (stringOne && stringTwo) {
    return stringOne.slice(0, 1) + stringTwo.slice(0, 1);
  } else {
    return stringOne.slice(0, 1);
  }
};

export const getWalletName = (str) => {
  return str?.slice(str?.split(" ")[0].length, str.length);
};

export const defaultImage = User;

export const getReceiverInfo = (transaction) => {
  if (!transaction) return {};

  const transactionInfo = transaction.receiver.split("-");

  if (transactionInfo.length < 2) {
    return {
      receiverAcctName: transactionInfo[0],
      receiverAcctNumber: "N/A",
      receiverBankName: "N/A",
    };
  }

  const [receiverBankName, receiverAcctNumber, receiverAcctName] =
    transactionInfo;

  return { receiverBankName, receiverAcctNumber, receiverAcctName };
};

export const formatCurrency = (amount) => {
  const sanitizedAmount =
    typeof amount === "string"
      ? parseInt("₦100".match(/\d/g).join(""))
      : amount;
  return (
    "₦" +
    parseInt(sanitizedAmount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
  );
};

export const formatTextToUppercase = (text) =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export const formatDate = (dateTime) => {
  const date = new Date(dateTime);
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};
