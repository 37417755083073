import axiosInstance2 from "./UnAuthApi";
import axiosInstance from "./api";

export const GetUserAccount = async () => {
  try {
    const url = "/user_setting";
    const res = await axiosInstance.get(url);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const UpdateProfile = async (payload) => {
  try {
    const url = "/users/update";
    const res = await axiosInstance.put(url, payload);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const UpdateSocials = async (payload) => {
  try {
    const url = "user_setting/socials";
    const res = await axiosInstance.put(url, payload);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const UpdateImage = async (payload) => {
  try {
    const url = "user_setting/profile/image";
    const formData = new FormData();

    formData.append("profileImage", payload);

    const res = await axiosInstance.put(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res.data;
  } catch (err) {
    throw err;
  }
};

export const UploadFile = async (payload) => {
  try {
    const url = "file/upload";
    const formData = new FormData();

    formData.append("file", payload);

    const res = await axiosInstance.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res.data;
  } catch (err) {
    throw err;
  }
};

export const PublishNotification = async (payload) => {
  const url = "push-notifications/message";
  try {

    const res = await axiosInstance.post(url, payload);

    return res.data;
  } catch (err) {
    throw err;
  }
};
